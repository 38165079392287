@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Apercu";
  src: url("../public/fonts/ApercuPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Futura";
}

.glowing-text {
  text-shadow: 0 0 0px rgba(255, 255, 255, 0.7),
    0 0 10px rgba(255, 255, 255, 0.6), 0 0 20px rgba(255, 255, 255, 0.5);
}

.custom-toast {
  background: linear-gradient(to bottom right, #31251d, #1b2c3d) !important;
  color: white !important;
  border: 1px solid white !important;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

/* Custom CSS to style the job description content */
.job-description {
  font-size: 1rem;
  line-height: 1.5;
}

.job-description h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.job-description ol {
  list-style-type: decimal;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.job-description ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.job-description li {
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.job-description p {
  margin-bottom: 1rem;
}

/* button  */
/* src/styles/custom.css */
.custom-button {
  display: flex;
  padding: 4px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: white;
  color: black;
  transition: box-shadow 0.3s ease-in-out;
}

.custom-button:hover {
  box-shadow: 0px 4px 24px rgba(255, 255, 255, 0.6); /* Brighter shadow on hover */
}

@keyframes dot-flash {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dot-flash::after {
  content: " .";
  animation: dot-flash 1.5s infinite steps(1);
}

.dot-flash::after {
  content: " .";
  animation: dot-flash 2.5s infinite steps(1) alternate;
}

.dot-flash::after {
  content: " ..";
  animation: dot-flash 3.5s infinite steps(1) alternate-reverse;
}

.dot-flash::after {
  content: " ...";
  animation: dot-flash 4.5s infinite steps(1) linear;
}
