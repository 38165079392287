@keyframes imageSwap {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-imageSwap {
  height: 70px;
  position: relative;
}

.animate-imageSwap img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: imageSwap 2s infinite;
  transition: opacity 0.5s ease-in-out;
}

.animate-imageSwap img:nth-child(2) {
  animation-delay: 1s;
}
